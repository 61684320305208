<template>
  <div class="main-content-container container-fluid px-4">
    <!-- Page Header -->
    <div class="page-header row no-gutters py-4">
      <div class="col-12 col-sm-4 text-center text-sm-left mb-0">
        <h3 class="page-title">Master Role</h3>
      </div>
    </div>

    <!-- Default Light Table -->
    <div class="row">
      <div class="col">
        <div class="card card-small mb-4">
          <div class="card-header border-bottom header-table">
            <h6 class="m-0">Data Role</h6>
            <div class="text-right">
                <button class="btn btn-outline-purple" @click="handleClick">Tambah</button>
            </div>
          </div>
          <div class="card-body p-0 pb-3 text-center" style="overflow-x:auto;">
            <table class="table mb-0">
              <thead class="bg-light">
                <tr>
                  <th scope="col" class="border-0" >Nama Role</th>
                  <th scope="col" class="border-0" >Keterangan</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="loading">
                  <td colspan="5">
                    <div class="loading">
                      <i class="fa fa-spinner fa-spin fa-2x"></i>
                    </div>
                  </td>
                </tr>
                <tr v-else-if="masterRole.length == 0">
                  <td colspan="6" style="text-align: center;">
                    Belum ada data
                  </td>
                </tr>
                <tr v-for="(item, index) in masterRole" :key="index" v-else>
                  <td>{{ item.namaRole }}</td>
                  <td>{{ item.keterangan }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <d-modal v-if="showAddModal" @close="handleClose" class="modal-index" centered>
        <d-modal-header>
            <d-modal-title>Tambah Master Role</d-modal-title>
        </d-modal-header>
        <d-modal-body>
            <RoleAdd 
              @close="handleClose"
              @success="fetchData"
            />
        </d-modal-body>
    </d-modal>

    <HandleServerError 
      :error="serverFailed"
      :show="showError"
      @close="hideError"
    />

  </div>
</template>

<script>
import RoleAdd from '@/views/master-role/RoleAdd.vue';
import HandleServerError from '@/components/element/HandleServerError.vue'
import { RepositoryAPI } from '@/api/repository_api';
const callApi  = RepositoryAPI.get('no_auth');

export default {
  name: 'master-role',
  data() {
    return{
      loading: false,
      loadingDelete: false,
      lengthData: 0,
      userProfile: null,
      rsvpData: null,
      showAddModal: false,
      masterRole: [],
      totalData: 0,
      editModal: false,
      editItem: {},
      serverFailed: {},
      showError: false
    }
  },
  components: {
    RoleAdd,
    HandleServerError
  },
  mounted() {
    this.fetchData();
  },
  methods:{
    async fetchData() {
      this.loading = true;
      try {
          const res = await callApi.getAllRole();
          this.masterRole = res.data.data.data;
          this.totalData = res.data.data.totalData;
          this.loading = false;
      } catch(e) {
          console.log(e)
      }
    },
    handleClick() {
      this.showAddModal = true;
    },
    handleClose() {
      this.showAddModal = false;
    },
    pagination(page) {
      this.payload.pageNumber = page;
      this.fetchData();
    },
    serverError(value) {
      this.serverFailed = value;
      this.showError = value.show;
    },
    hideError(value) {
      this.showError = value;
    }
  },
  computed: {
  }
}
</script>
<style lang="scss" scoped>
.table{
  th{
    text-align: left;
  }
  td{
    text-align: left;
    .loading{
      color: #c00434;
      text-align: center;
    }
    .total-label{
      text-align: left !important;
      font-size: 16px;
      font-weight: 700;
    }
    .total-guest{
      text-align: left !important;
      font-size: 16px;
      font-weight: 700;
    }
  }
}
.gambarIcon {
  img {
    height: 40px;
    width: auto;
  }
}
.pagination-section {
    width: 370px;
    margin:0 auto;
    padding-top: 30px;
}
</style>
