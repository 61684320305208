import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    detailAlert: {},
    menuNavBar: {},
  },
  getters: {
    getAlert: state => state.detailAlert,
  },
  mutations: {
    updateAlert: (state, payload) => {
      state.detailAlert = payload
    },
    updateMenuNavBar: (state, payload) => {
      state.menuNavBar = payload
    },
  },
  actions: {
    saveMenuNavBar: ({ commit }, payload) => {
      commit("updateMenuNavBar", payload)
    },
    setAlert(state, payload) {
      console.log(payload)
      console.log(state)
      commit("updateAlert", payload);
    }
  },
  modules: {
  }
})
