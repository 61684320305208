// eslint-disable-next-line
import axios from 'axios'
import cookies from 'js-cookie'
// const httpProxy = require('http-proxy');
// let proxy = httpProxy.createProxyServer({});

const data_token = cookies.getJSON('token_');
let token
if(data_token !== undefined){
    token = data_token
}else{
    token = null
}

// const base_url = 'https://service.olebsai.com/api/';
// const base_url_auth = 'https://service.olebsai.com/api/';

const base_url = 'https://api.olebsai.com/api/';
const base_url_auth = 'https://api.olebsai.com/api/';

export const axiosApi = axios.create({
    baseURL : base_url_auth,
    headers:{
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Header' : '*',
        'Authorization' : 'Bearer '+token,
    },
});

export const axiosNoAuth = axios.create({
    baseURL : base_url,
    headers:{
        'Content-type': 'application/json'
    },
});