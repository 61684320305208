<template>
  <div class="main-content-container container-fluid px-4">
    <!-- Page Header -->
    <div class="page-header row no-gutters py-4">
      <div class="col-12 col-sm-4 text-center text-sm-left mb-0">
        <h3 class="page-title">Sub kategori</h3>
      </div>
    </div>

    <!-- Default Light Table -->
    <div class="row">
      <div class="col">
        <div class="card card-small mb-4">
          <div class="card-header border-bottom header-table">
            <h6 class="m-0">Data sub kategori</h6>
            <div class="text-right">
                <button class="btn btn-outline-purple" @click="handleClick">Tambah</button>
            </div>
          </div>
          <div class="card-body p-0 pb-3 text-center" style="overflow-x:auto;">
            <table class="table mb-0">
              <thead class="bg-light">
                <tr>
                  <th scope="col" class="border-0" >Sub Kategori</th>
                  <th scope="col" class="border-0" >Nama Kategori</th>
                  <th scope="col" class="border-0" >Keterangan</th>
                  <th scope="col" class="border-0">Aksi</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="loading">
                  <td colspan="5">
                    <div class="loading">
                      <i class="fa fa-spinner fa-spin fa-2x"></i>
                    </div>
                  </td>
                </tr>
                <tr v-else-if="subKategori.length == 0">
                  <td colspan="6" style="text-align: center;">
                    Belum ada data
                  </td>
                </tr>
                <tr v-for="(item, index) in subKategori" :key="index" v-else>
                  <td>{{ item.namaSubKategori }}</td>
                  <td>{{ item.namaKategori }}</td>
                  <td>{{ item.keterangan }}</td>
                  <td>
                    <div class="aliansi-button">
                    <button class="btn btn-outline-purple" @click="showEditModal(item)">
                      Edit
                    </button>
                    <d-button theme="danger" 
                      @click="deleteMasterKategori(item.subKategoriId)" 
                      :disabled="loadingDelete"
                    >Hapus
                    </d-button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="pagination-section">
              <pagination 
                :records="totalData" 
                v-model="payload.pageNumber" 
                :per-page="payload.pageSize"
                :options="paginationOpt" 
                @paginate="pagination" 
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <d-modal v-if="showAddModal" @close="handleClose" class="modal-index" centered>
        <d-modal-header>
            <d-modal-title>Tambah Sub Kategori</d-modal-title>
        </d-modal-header>
        <d-modal-body>
            <SubKategoriAdd 
              @close="handleClose"
              @success="fetchData"
            />
        </d-modal-body>
    </d-modal>

    <d-modal v-if="editModal" @close="hideEditModal" class="modal-index" centered>
        <d-modal-header>
            <d-modal-title>Edit Sub Kategori</d-modal-title>
        </d-modal-header>
        <d-modal-body>
            <SubKategoriEdit
              @close="hideEditModal"
              @success="fetchData"
              :editItem="editItem"
              @error="serverError"
            />
        </d-modal-body>
    </d-modal>

    <HandleServerError 
      :error="serverFailed"
      :show="showError"
      @close="hideError"
    />


  </div>
</template>

<script>
import SubKategoriAdd from '@/views/sub-kategori/SubKategoriAdd.vue';
import SubKategoriEdit from '@/views/sub-kategori/SubKategoriEdit.vue';
import HandleServerError from '@/components/element/HandleServerError.vue'
import { RepositoryAPI } from '@/api/repository_api';
const callApi  = RepositoryAPI.get('auth_admin');

export default {
  name: 'sub-kategori',
  data() {
    return{
      loading: false,
      loadingDelete: false,
      lengthData: 0,
      userProfile: null,
      rsvpData: null,
      showAddModal: false,
      subKategori: [],
      totalData: 0,
      payload: {
        pageNumber: 1,
        pageSize: 10,
        sortBy:'created',
        sortDirection: 'DESC'
      },
      editModal: false,
      editItem: {},
      paginationOpt: {
        chunk: 5
      },
      serverFailed: {},
      showError: false
    }
  },
  components: {
    SubKategoriAdd,
    SubKategoriEdit,
    HandleServerError
  },
  mounted() {
    this.fetchData();
  },
  methods:{
    async fetchData() {
      this.loading = true;
      try {
          const res = await callApi.getSubKategoriAll(this.payload);
          this.subKategori = res.data.data.data;
          this.totalData = res.data.data.totalData;
          this.loading = false;
      } catch(e) {
          console.log(e)
      }
    },
    handleClick() {
      this.showAddModal = true;
    },
    handleClose() {
      this.showAddModal = false;
    },
    showEditModal(value) {
      this.editModal = true;
      this.editItem = value;
    },
    hideEditModal() {
      this.editModal = false;
    },
    deleteMasterKategori(id) {
      this.$swal.fire({
        title: 'Apakah anda yakin?',
        text: "Data yang dihapus tidak bisa dikembalikan!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#c00434',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, hapus data!',
        cancelButtonText: 'Batal'
      }).then(async(result) => {
        if (result.isConfirmed) {
          this.loadingDelete = true;
          try {
            await callApi.deleteSubKategori(id);
            this.$swal.fire(
              'Dihapus!',
              'Data berhasil dihapus.',
              'success'
            )
            this.loadingDelete = false;
            this.fetchData();
          } catch(e) {
            this.loadingDelete = false;
            const error = {
              data: e.response.data,
              show: true
            }
            this.serverError(error)
          }
        }
      })
    },
    pagination(page) {
      this.payload.pageNumber = page;
      this.fetchData();
    },
    serverError(value) {
      this.serverFailed = value;
      this.showError = value.show;
    },
    hideError(value) {
      this.showError = value;
    }
  },
  computed: {
  }
}
</script>
<style lang="scss" scoped>
.table{
  th{
    text-align: left;
  }
  td{
    text-align: left;
    .loading{
      color: #c00434;
      text-align: center;
    }
    .total-label{
      text-align: left !important;
      font-size: 16px;
      font-weight: 700;
    }
    .total-guest{
      text-align: left !important;
      font-size: 16px;
      font-weight: 700;
    }
  }
}
.gambarIcon {
  img {
    height: 40px;
    width: auto;
  }
}
.pagination-section {
    width: 370px;
    margin:0 auto;
    padding-top: 30px;
}
</style>
