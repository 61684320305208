import { axiosNoAuth } from '@/api/config';

export default {
    signIn(data) {
        return axiosNoAuth.post('login', data);
    },
    signUp(data) {
        return axiosNoAuth.post('register', data);
    },
    resendVerification(data) {
        return axiosNoAuth.post('resend-verification', data);
    },
    getAllRole() {
        return axiosNoAuth.get('v1/master-role/list');
    },
    createRole(payload) {
        return axiosNoAuth.post('v1/master-role/create', payload);
    }
}