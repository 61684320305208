export default function () {
  return [
    {
      title: 'Home',
      to: {
        name: 'home-admin',
      },
      htmlBefore: '<i class="material-icons">home</i>',
      htmlAfter: '',
    },
    {
      title: 'Kategori',
      to: {
        name: 'master-kategori',
      },
      htmlBefore: '<i class="material-icons">assignment</i>',
      htmlAfter: '',
    },
    {
      title: 'Sub Kategori',
      to: {
        name: 'sub-kategori',
      },
      htmlBefore: '<i class="material-icons">assignment_returned</i>',
      htmlAfter: '',
    },
    {
      title: 'Pengguna',
      htmlBefore: '<i class="material-icons">people</i>',
      to: {
        name: 'master-users',
      },
    },
    // {
    //   title: 'Role',
    //   htmlBefore: '<i class="material-icons">accessibility</i>',
    //   to: {
    //     name: 'master-role',
    //   },
    // },
    {
      title: 'Approval Toko',
      htmlBefore: '<i class="material-icons">store</i>',
      to: {
        name: 'master-approval',
      },
    },
    {
      title: 'Approval Produk',
      htmlBefore: '<i class="material-icons">widgets</i>',
      to: {
        name: 'master-approval-produk',
      },
    },
 
  ];
}
