<template>
  <d-navbar-nav class="border-left flex-row">
    <li class="nav-item dropdown">
       <a class="nav-link dropdown-toggle text-nowrap px-3" v-d-toggle.user-actions >
        <img class="user-avatar rounded-circle mr-2" src="@/assets/images/avatars/0.jpg" alt="User Avatar"> <span class="d-none d-md-inline-block" v-if="userProfile !== null">{{ userProfile.name }}</span>
      </a>
      <d-collapse id="user-actions" class="dropdown-menu dropdown-menu-small">
        <a class="dropdown-item dropzing" @click="getProfile"><i class="material-icons">&#xE7FD;</i> Profil</a>
        <a class="dropdown-item text-danger dropzing" @click="signOut">
          <i class="material-icons text-danger">&#xE879;</i> Logout
        </a>
      </d-collapse>
    </li>
  </d-navbar-nav>
</template>
<script>
import Cookies from "js-cookie";
export default {
  props: {
    userProfile: {
      default : null,
    },
  },
  data() {
    return {
      menuOpen: true
    }
  },
  methods: {
    toggleAction(){
      this.menuOpen = true;
    },
    signOut() {
      Cookies.remove("token_");
      this.$router.push('/signin');
    },
    getProfile() {
      this.$router.push({name:'profil'}).catch(()=>{});
    }
  }
}
</script>

<style lang="scss" scoped>
  .nav-link:hover {
    cursor: pointer;
  }
.dropzing{
  cursor: pointer;
}

</style>
