<template>
    <d-card class="card-small mb-4">
        <!-- Card Header -->
        <d-card-header class="border-bottom">
            <h6 class="m-0">{{ title }}</h6>
        </d-card-header>
        <d-card-body>
            <d-alert dismissible
                :show="alert.timeUntilDismissed"
                theme="danger"
                @alert-dismissed="alert.timeUntilDismissed = 0"
                @alert-dismiss-countdown="handleTimeChange">
            >
                <b>Maaf,</b> Template saat ini belum tersedia. 
            </d-alert>
            <d-row>
                <d-col lg="4">
                    <div class="list-template active">
                        <d-img fluid thumbnail :src="freeTemplate" />
                        <div class="free">Gratis</div>
                    </div>
                </d-col>
                <d-col lg="4">
                    <div class="list-template" @click="templateComingSoon">
                        <d-img fluid thumbnail :src="comingSoon" />
                    </div>
                </d-col>
                <d-col lg="4">
                    <div class="list-template">
                        <d-img fluid thumbnail :src="custom" />
                    </div>
                </d-col>
            </d-row>
        </d-card-body>
        <d-card-footer>
            <d-row>
                <d-col md="12">
                    <div class="text-right">
                        <d-button type="submit" theme="warning" @click="toNext">Selanjutnya</d-button>
                    </div>
                </d-col>
            </d-row>
        </d-card-footer>
    </d-card>
</template>

<script>
import freeTemplate from '@/assets/images/template/default.png';
import comingSoon from '@/assets/images/template/coming_soon.png';
import custom from '@/assets/images/template/custom.png';
export default {
  name: 'pilih-template',
  props: {
    /**
     * The component title.
     */
    title: {
      type: String,
      default: 'Pilih Template',
    },
  },
  data() {
    return {
        freeTemplate: freeTemplate,
        comingSoon: comingSoon,
        custom: custom,
        alert: {
            duration: 5,
            timeUntilDismissed: 0
        }
    }
  },
  methods: {
    handleTimeChange (time) {
      this.alert.timeUntilDismissed = time
    },
    templateComingSoon () {
      this.alert.timeUntilDismissed = this.alert.duration
    },
    toNext(){
        this.$emit('openPage', 'BasicInfo');
    }
  }
};
</script>

<style lang="scss" scoped>
    .list-template {
        margin-bottom: 30px;
        position: relative;
        cursor: pointer;
        img:hover {
            border: 2px solid #c00434;
        }
        .free {
            position: absolute;
            bottom: 45%;
            width: 100%;
            padding: 8px 0px;
            text-align: center;
            background-color: rgba(176, 131, 58, 0.7);
            color: #fff;
            font-size: 27px;
            text-transform: uppercase;
        }
    }
    .active{
        img {
            border: 2px solid #c00434;
        }
    }
</style>