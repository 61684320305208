<template>
<div div class="login">
    <div class="container d-flex h-100">   
        <div class="row align-self-center w-100">
            <div class="col-md-4 mx-auto">
                
                <!-- Card -->
                <div class="card align-middle">

                    <!-- Card body -->
                    <div class="card-body">

                        <!-- Default form subscription -->
                        <div>
                            <div class="logo-login">
                                <img :src="logo"/>
                            </div>
                            <p class="h3 text-center py-4">Sign Up</p>
                            <div class="resend-email" v-if="succesSign">
                                <p>{{messageError}}</p>
                                <div class="resend-area">
                                    <div class="small-resend">
                                        Proses mengirim email verifikasi tidak akan lama, jika lebih dari 10 menit tidak mendapat email klik tombol di bawah ini
                                    </div>
                                    <button class="btn btn-outline-purple" v-if="loading"><i class="fa fa-spinner fa-spin"></i> Loading</button>
                                    <button class="btn btn-outline-purple" @click="resendVerify" v-else>Kirim Verifikasi Email<i class="far fa-paper-plane ml-2"></i></button>
                                </div>
                            </div>
                            <div v-else>
                                <div class="sign-in-error" v-if="messageError">{{messageError}}</div>
                                <label for="defaultFormCardNameEx" class="grey-text font-weight-light">Nama Lengkap</label>
                                <input type="text" class="form-control" placeholder="nama lengkap" v-model="validation.name">
                                <br>
                                <label for="defaultFormCardNameEx" class="grey-text font-weight-light">Email</label>
                                <input type="email" placeholder="email" class="form-control" v-model="validation.email">
                                <br>
                                <label for="defaultFormCardEmailEx" class="grey-text font-weight-light">Password</label>
                                <input type="password" placeholder="password" class="form-control" v-model="validation.password">
                                <br>
                                <label for="defaultFormCardEmailEx" class="grey-text font-weight-light">Ulangi Password</label>
                                <input type="password" placeholder="ulangi password" class="form-control" v-model="validation.rePassword">
                                <div class="text-center py-4 mt-3">
                                    <button class="btn btn-outline-purple" v-if="loading"><i class="fa fa-spinner fa-spin"></i> Loading</button>
                                    <button class="btn btn-outline-purple" :disabled="disable" @click="signup" v-else>Sign In<i class="far fa-paper-plane ml-2"></i></button>
                                </div>
                            </div>
                        </div>
                        <div class="have-account">
                            Sudah punya akun? <span @click="haveAccount">Sign In</span>
                        </div>
                        <!-- Default form subscription -->

                    </div>
                    <!-- Card body -->

                </div>
                <!-- Card -->

            </div>
        </div>
    </div>
</div>
</template>
<script lang="js">
import logo from '@/assets/images/logo_jd.png'
import Cookies from "js-cookie";
import { RepositoryAPI } from '@/api/repository_api';
const callApi  = RepositoryAPI.get('no_auth');
export default {
    data() {
      return {
        logo: logo,
        data: null,
        param: {
            name: null,
            email: null,
            password: null,
        },
        validation: {
          name: null,
          email: null,
          password: null,
          rePassword: null,
        },
        loading: false,
        messageError: null,
        disable: true,
        succesSign: false,
        paramResend: {
          name: null,
          email: null,
          id: null,
        }
      }  
    },
    mounted(){
        
    },
    watch:{
        validation:{
            deep: true,
            handler: function(value){
                if(
                    value.name !== null && 
                    this.validateEmail(value.email) == true &&
                    value.password !== null &&
                    value.rePassword !== null &&
                    value.password == value.rePassword
                ){
                    this.disable = false;
                    this.param.name = value.name;
                    this.param.email = value.email;
                    this.param.password = value.password;
                } else {
                    this.disable = true;
                }
            }
        }
    },
    methods: {
        haveAccount() {
            this.$router.push('/signin');
        },
        async signup() {
            this.loading = true;
            try {
                const data = await callApi.signUp(this.param);
                if(data.status == 200) {
                    if(data.data.status == true){
                        this.loading = false;
                        this.messageError = data.data.message;
                        this.succesSign = true;
                        this.paramResend.name = data.data.data.name;
                        this.paramResend.email = data.data.data.email;
                        this.paramResend.id = data.data.data.id;
                    } else {
                        this.succesSign = false;
                        this.loading = false;
                        this.messageError = data.data.message;
                    }
                }
            } catch(e) {
                this.messageError = 'Sign Up gagal';
                this.loading = false;
            }
        },
        async resendVerify() {
            this.loading = true;
            try {
                const data = await callApi.resendVerification(this.paramResend);
                if (data.status == 200) {
                    this.loading = false;
                    this.messageError = 'Kami sudah mengirim ulang email verifikasi';
                }
            } catch(e) {
                console.log(e);
            }
        },
        validateEmail(value){      
            const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
            return regex.test(value);
        }, 
        validateToken() {
            const token = Cookies.getJSON("jaditamuku");
            if (token) {
                this.$router.push('/home');
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.align-middle {
    vertical-align: middle !important;
    margin-top: 10%;
    margin-bottom: 10%;
}
.btn-outline-purple {
    background: #c00434;
    color: #fff;
    &:disabled{
        color: #000;
        background: #C3EAFF;
    }
}
.logo-login {
    width: 100%;
    text-align: center;
    img{
        width: 70px;
    }
}
.card-body{
    .h3{
        font-size: 16px;
    }
    .sign-in-error{
        background-color: #f37c73;
        text-align: center;
        color: #fff;
        font-weight: 300;
        margin-bottom: 15px;
        padding: 5px 20px;
    }
    .have-account {
        font-weight: 300 !important;
        text-align: center;
        span {
            font-weight: 300;
            color: #c00434;
            &:hover{
                cursor: pointer;
            }
        }
    }
    .resend-email{
        text-align: center;
        p{
            font-weight: 300;
            margin-bottom: 0px;
        }
        .resend-area{
            padding: 15px;
            .small-resend {
                font-weight: 300;
                font-size: 10px;
                margin-bottom: 15px;
            }
        }
    }
}
</style>