import { axiosApi } from '@/api/config';

export default {
    // MASTER KATEGORI
    getMasterKategoriAll(payload){
       return axiosApi.get(`v1/master-kategori/get-all?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}&sortBy=${payload.sortBy}&sortDirection=${payload.sortDirection}`);
    },
    getMasterById(id) {
        return axiosApi.get(`v1/master-kategori/get-by-id/${id}`);
    },
    editMasterCategory(payload) {
        return axiosApi.post('v1/master-kategori/update', {
            kategoriId: payload.kategoriId,
            gambarIcon: payload.gambarIcon,
            keterangan: payload.keterangan,
            namaKategori: payload.namaKategori
        });
    },
    createMasterKategori(payload) {
        return axiosApi.post('v1/master-kategori/create', {
            gambarIcon: payload.gambarIcon,
            keterangan: payload.keterangan,
            namaKategori: payload.namaKategori
        });
    },
    deleteMasterKategori(id) {
        return axiosApi.post('v1/master-kategori/delete', {
            kategoriId: id
        });
    },
    // MASTER USERS
    getUserProfileDetail() {
        return axiosApi.get('v1/master-user/get-by-token')
    },
    getMasterUsersAll(payload){
        return axiosApi.get(`v1/master-user/get-all?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}&sortBy=${payload.sortBy}&sortDirection=${payload.sortDirection}`);
    },
    createMasterUser(payload) {
        return axiosApi.post('/v1/master-user/create',{
            email: payload.email,
            gambarProfile: payload.gambarProfile,
            keterangan: payload.keterangan,
            namaRole: payload.namaRole,
            namaUser: payload.namaUser,
            nomorHp: payload.nomorHp,
            password: payload.password
        });
    },
    editUser(payload){
        return axiosApi.post('v1/master-kategori/update', payload);
    },
    deleteUser(id) {
        return axiosApi.post('v1/master-user/delete', {
            userId: id
        });
    },
    // SUB KATEGORI
    getSubKategoriAll(payload){
        return axiosApi.get(`v1/master-sub-kategori/get-all?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}&sortBy=${payload.sortBy}&sortDirection=${payload.sortDirection}`);
    },
    createSubKategori(payload) {
        return axiosApi.post('v1/master-sub-kategori/create', payload);
    },
    editSubKategori(payload) {
        return axiosApi.post('v1/master-sub-kategori/update', payload);
    },
    deleteSubKategori(id) {
        return axiosApi.post('v1/master-sub-kategori/delete', {
            subKategoriId: id
        });
    },
    //APPROVAL TOKO
    getApprovalTokoAll(payload){
        return axiosApi.get(`v1/approval-toko/get-all?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}&sortBy=${payload.sortBy}&sortDirection=${payload.sortDirection}`);
    },
    approveToko(id) {
        return axiosApi.post('v1/approval-toko/approve/' + id);
    },
    //APPROVAL PRODUK
    getApprovalProdukAll(payload) {
        return axiosApi.get(`v1/approval-produk/get-all?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}&sortBy=${payload.sortBy}&sortDirection=${payload.sortDirection}`);
    },
    approveProduk(id) {
        return axiosApi.post('v1/approval-produk/approve/' + id);
    },

    getUserProfile() {
        return axiosApi.get('user-profile');
    },

    detailProduct(id) {
        return axiosApi.get('v1/produk/get-by-id/' + id);
    }
  
}