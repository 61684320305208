<template>
  <div class="main-content-container container-fluid px-4">
    <!-- Page Header -->
    <div class="page-header row no-gutters py-4">
      <div class="col-12 col-sm-4 text-center text-sm-left mb-0">
        <h3 class="page-title">RSVP</h3>
      </div>
    </div>

    <!-- Default Light Table -->
    <div class="row">
      <div class="col">
        <div class="card card-small mb-4">
          <div class="card-header border-bottom">
            <h6 class="m-0">Data Tamu</h6>
          </div>
          <div class="card-body p-0 pb-3 text-center" style="overflow-x:auto;">
            <table class="table mb-0">
              <thead class="bg-light">
                <tr>
                  <th scope="col" class="border-0" width="10">No</th>
                  <th scope="col" class="border-0" width="200">Nama</th>
                  <th scope="col" class="border-0" width="250">Alamat</th>
                  <th scope="col" class="border-0">Telp</th>
                  <th scope="col" class="border-0">Kehadiran</th>
                  <th scope="col" class="border-0">Tamu</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="loading">
                  <td colspan="5">
                    <div class="loading">
                      <i class="fa fa-spinner fa-spin fa-2x"></i>
                    </div>
                  </td>
                </tr>
                <tr v-else-if="lengthData == 0">
                  <td colspan="6" style="text-align: center;">
                    Belum ada data
                  </td>
                </tr>
                <tr v-for="(item, index) in rsvpData" :key="index" v-else>
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.address }}</td>
                  <td>{{ item.telp }}</td>
                  <td v-if="item.attendance == 0">
                    <d-badge theme="danger">Tidak</d-badge>
                  </td>
                  <td v-else>
                    <d-badge theme="success">Hadir</d-badge>
                  </td>
                  <td>{{ item.attendance + ' Orang'}}</td>
                </tr>
                <tr v-if="totalGuest !== 0">
                  <td colspan="5">
                    <div class="total-label">Estimasi Kehadiran</div>
                  </td>
                  <td>
                    <div class="total-guest">
                      {{ totalGuest }} Orang
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Cookies from "js-cookie";
import { RepositoryAPI } from '@/api/repository_api';
const callApi  = RepositoryAPI.get('auth_jaditamuku');
export default {
  data() {
    return{
      loading: false,
      lengthData: 0,
      userProfile: null,
      rsvpData: null,
    }
  },
  mounted() {
    this.getDataUser();
  },
  methods:{
    async getData() {
      this.loading = true;
      try {
        const data = await callApi.getRsvp(this.userProfile.id);
        if(data.status == 200) {
          this.loading = false;
          this.rsvpData = data.data.response;
          this.lengthData = data.data.response.length;
        }
      } catch(e) {
        console.log(e);
      }
    },
    async getDataUser() {
      try {
        const data = await callApi.getUserProfile();
        if(data.status == 200) {
          this.userProfile = data.data;
          this.getData();
        }
      } catch(e) {
        console.log(e);
      }
    }
  },
  computed: {
    totalGuest() {
      if(this.rsvpData) {
        const people = [];
        this.rsvpData.forEach((dtx) => {
          people.push(dtx.attendance);
        });
        let amount = people.reduce((a, b) => +a + +b, 0);
        return amount;
      } else {
        return 0;
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.table{
  th{
    text-align: left;
  }
  td{
    text-align: left;
    .loading{
      color: #c00434;
      text-align: center;
    }
    .total-label{
      text-align: left !important;
      font-size: 16px;
      font-weight: 700;
    }
    .total-guest{
      text-align: left !important;
      font-size: 16px;
      font-weight: 700;
    }
  }
}
</style>
