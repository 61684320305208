
// import no_auth from '@/api/no_auth.js';
// import auth_admin from '@/api/auth_admin.js';

import no_auth from '../api/no_auth.js'
import auth_admin from '../api/auth_admin.js';

const repositories = {
    no_auth : no_auth,
    auth_admin : auth_admin,
};

export const RepositoryAPI = {
    get : name => repositories[name]
};

