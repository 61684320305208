<template>
    <d-card class="card-small mb-4">
        <!-- Card Header -->
        <d-card-header class="border-bottom">
            <h6 class="m-0">{{ title }}</h6>
        </d-card-header>
        <d-card-body>
            <div class="menu-invitation">
                <div class="menu-item" :class="{ active: styleClass == item.target }" v-for="(item, index) in menu" :key="index">
                    <button @click="pickMenu(item.target)">{{ item.title }}</button>
                </div>
            </div>
        </d-card-body>
    </d-card>
</template>

<script>
export default {
  name: 'side-menu',
  props: {
    title: {
      type: String,
      default: 'Menu',
    },
    styleClass: {
        type: String,
    }
  },
  data() {
      return {
        menu: [
            {
                id: 1,
                title: 'Pilih Template',
                target: 'PilihTemplate'
            },
            {
                id: 2,
                title: 'Informasi Mempelai',
                target: 'BasicInfo'
            }
        ]
      }
  },
  methods: {
    pickMenu(item) {
      this.$emit('openPage', item);
    }
  }
};
</script>

<style lang="scss" scoped>
.menu-invitation {
    .active {
        background-color: #f5f6f8;
        button {
            color: #c00434;
        }
    }
    .menu-item {
        padding: 15px 0px;
        button {
            background: transparent;
            border: 0px;
        }
    }
}
</style>