<template>
  <div class="main-content-container container-fluid px-4">
    <!-- Page Header -->
    <div class="page-header row no-gutters py-4">
      <div class="col-12 col-sm-4 text-center text-sm-left mb-0">
        <h3 class="page-title">Master Pengguna</h3>
      </div>
    </div>

    <!-- Default Light Table -->
    <div class="row">
      <div class="col">
        <div class="card card-small mb-4">
          <div class="card-header border-bottom header-table">
            <h6 class="m-0">Data Pengguna</h6>
            <div class="text-right">
                <button class="btn btn-outline-purple" @click="handleClick">Tambah Administrator</button>
            </div>
          </div>
          <div class="card-body p-0 pb-3 text-center" style="overflow-x:auto;">
            <table class="table mb-0">
              <thead class="bg-light">
                <tr>
                  <th scope="col" class="border-0" >Nama </th>
                  <th scope="col" class="border-0" >Peran</th>
                  <th scope="col" class="border-0" >nomor HP</th>
                  <th scope="col" class="border-0">Aksi</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="loading">
                  <td colspan="5">
                    <div class="loading">
                      <i class="fa fa-spinner fa-spin fa-2x"></i>
                    </div>
                  </td>
                </tr>
                <tr v-else-if="masterUsers.length == 0">
                  <td colspan="6" style="text-align: center;">
                    Belum ada data
                  </td>
                </tr>
                <tr v-for="(item, index) in masterUsers" :key="index" v-else>
                  <td>{{ item.namaUser }}</td>
                  <td>{{ item.namaRole }}</td>
                  <td>{{ item.nomorHp }}</td>
                  <td>
                    <div class="aliansi-button">
                    <button class="btn btn-outline-purple" @click="showEditModal(item)">
                      Edit
                    </button>
                    <d-button theme="danger" 
                      @click="deleteData(item.userId)" 
                      :disabled="loadingDelete"
                    >Hapus
                    </d-button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="pagination-section">
              <pagination 
                :records="totalData" 
                v-model="payload.pageNumber" 
                :per-page="payload.pageSize"
                :options="paginationOpt" 
                @paginate="pagination" 
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <d-modal v-if="showAddModal" @close="handleClose" class="modal-index" centered>
        <d-modal-header>
            <d-modal-title>Tambah Pengguna</d-modal-title>
        </d-modal-header>
        <d-modal-body>
            <UserAdd 
              @close="handleClose"
              @success="fetchData"
              @error="serverError"
            />
        </d-modal-body>
    </d-modal>

    <d-modal v-if="editModal" @close="handleEditModal" class="modal-index" centered>
        <d-modal-header>
            <d-modal-title>Edit Pengguna</d-modal-title>
        </d-modal-header>
        <d-modal-body>
          <UserEdit 
            :userItem="editItem"
            @error="serverError"
            @close="handleEditModal"
          />
        </d-modal-body>
    </d-modal>
    <HandleServerError 
      :error="serverFailed"
      :show="showError"
      @close="hideError"
    />
  </div>
</template>

<script>
import UserAdd from '@/views/master-user/UserAdd.vue';
import UserEdit from '@/views/master-user/UserEdit.vue';
import HandleServerError from '@/components/element/HandleServerError.vue'
import { RepositoryAPI } from '@/api/repository_api';
const callApi  = RepositoryAPI.get('auth_admin');

export default {
  name: 'master-users',
    data() {
        return {
          loading: false,
          showAddModal: false,
          editModal: false,
          masterUsers: [],
          totalData: 0,
          payload: {
              pageNumber: 1,
              pageSize: 10,
              sortBy:'created',
              sortDirection: 'DESC'
          },
          loadingDelete: false,
          editItem: {},
          serverFailed: {},
          showError: false
        }
    },
    components:{
      UserAdd,
      UserEdit,
      HandleServerError
    },
    mounted() {
        this.fetchData();
    },
    methods: {
      async fetchData() {
          this.loading = true;
          try {
              const res = await callApi.getMasterUsersAll(this.payload);
              this.masterUsers = res.data.data.data;
              this.totalData = res.data.data.totalData;
              this.loading = false;
          } catch(e) {
              console.log(e)
          }
      },
      handleClick() {
          this.showAddModal = true;
      },
      handleClose() {
        this.showAddModal = false;
      },
      showEditModal(value) {
        this.editModal = true;
        this.editItem = value;
      },
      handleEditModal() {
        this.editModal = false;
      },
      deleteData(id) {
        this.$swal.fire({
          title: 'Apakah anda yakin?',
          text: "Data yang dihapus tidak bisa dikembalikan!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#c00434',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya, hapus data!',
          cancelButtonText: 'Batal'
        }).then(async(result) => {
          if (result.isConfirmed) {
            this.loadingDelete = true;
            try {
              await callApi.deleteUser(id);
              this.$swal.fire(
                'Dihapus!',
                'Data berhasil dihapus.',
                'success'
              )
              this.loadingDelete = false;
              this.fetchData();
            } catch(e) {
              console.log(e)
            }
          }
        })
      },
      pagination(page) {
        this.payload.pageNumber = page;
        this.fetchData();
      },
      serverError(value) {
        this.serverFailed = value;
        this.showError = value.show;
      },

      hideError(value) {
        this.showError = value;
      }
        
    } 
}
</script>

<style lang="scss" scoped>
.table{
  th{
    text-align: left;
  }
  td{
    text-align: left;
    .loading{
      color: #c00434;
      text-align: center;
    }
    .total-label{
      text-align: left !important;
      font-size: 16px;
      font-weight: 700;
    }
    .total-guest{
      text-align: left !important;
      font-size: 16px;
      font-weight: 700;
    }
  }
}

.pagination-section {
    width: 370px;
    margin:0 auto;
    padding-top: 30px;
}
</style>