<template>
  <div class="main-content-container container-fluid px-4">
    <!-- Page Header -->
    <div class="page-header row no-gutters py-4">
      <div class="col-12 col-sm-4 text-center text-sm-left mb-0">
        <h3 class="page-title">Master Approval Toko</h3>
      </div>
    </div>

    <!-- Default Light Table -->
    <div class="row">
      <div class="col">
        <div class="card card-small mb-4">
          <div class="card-header border-bottom header-table">
            <h6 class="m-0">Data Toko</h6>
          </div>
          <div class="card-body p-0 pb-3 text-center" style="overflow-x:auto;">
            <table class="table mb-0">
              <thead class="bg-light">
                <tr>
                  <th scope="col" class="border-0" >Nama Toko</th>
                  <th scope="col" class="border-0" >Alamat</th>
                  <th scope="col" class="border-0">Aksi</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="loading">
                  <td colspan="5">
                    <div class="loading">
                      <i class="fa fa-spinner fa-spin fa-2x"></i>
                    </div>
                  </td>
                </tr>
                <tr v-else-if="masterApproval.length == 0">
                  <td colspan="6" style="text-align: center;">
                    Belum ada data
                  </td>
                </tr>
                <tr v-for="(item, index) in masterApproval" :key="index" v-else>
                  <td>{{ item.namaToko }}</td>
                  <td>{{ item.alamat }}</td>
                  <td>
                    <div class="aliansi-button">
                      <button 
                        class="btn btn-outline-purple" 
                        @click="openDetail(item)"
                      >
                        Detail Toko
                      </button>
                      <button 
                        class="btn btn-outline-purple" 
                        @click="approveThis(item)"
                        :disabled="loadingDelete"
                      >
                        <i class="material-icons">done</i> Setujui Toko
                      </button>
                      
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="pagination-section">
              <pagination 
                :records="totalData" 
                v-model="payload.pageNumber" 
                :per-page="payload.pageSize"
                :options="paginationOpt" 
                @paginate="pagination" 
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <d-modal v-if="modalDetail" @close="hideModalDetail" class="modal-index" centered>
      <d-modal-header>
        <d-modal-title>Detail Toko</d-modal-title>
      </d-modal-header>
      <d-modal-body>
        <div class="detailToko">
          <table>
            <tr>
              <td width="150">Nama Toko</td>
              <td>:</td>
              <td>{{ detailToko.namaToko }}</td>
            </tr>
            <tr>
              <td width="150">Deskripsi Toko</td>
              <td>:</td>
              <td>{{ detailToko.keterangan }}</td>
            </tr>
            <tr>
              <td width="150">Provinsi</td>
              <td>:</td>
              <td>{{ detailToko.provinsi }}</td>
            </tr>
            <tr>
              <td width="150">Kabupaten/Kota</td>
              <td>:</td>
              <td>{{ detailToko.kota }}</td>
            </tr>
            <tr>
              <td width="150">Kecamatan</td>
              <td>:</td>
              <td>{{ detailToko.kecamatan }}</td>
            </tr>
            <tr>
              <td width="150">Alamat</td>
              <td>:</td>
              <td>{{ detailToko.alamat }}</td>
            </tr>
            <tr>
              <td width="150">Kode Pos</td>
              <td>:</td>
              <td>{{ detailToko.kodePos}}</td>
            </tr>
            <tr>
              <td width="150">Bank</td>
              <td>:</td>
              <td>{{ detailToko.bank}}</td>
            </tr>
            <tr>
              <td width="150">No Rekening</td>
              <td>:</td>
              <td>{{ detailToko.noRekening}}</td>
            </tr>
            <tr>
              <td width="150">Pemilik Rekening</td>
              <td>:</td>
              <td>{{ detailToko.namaPemilikRekening}}</td>
            </tr>
          </table>
          
        </div>
      </d-modal-body>
    </d-modal>

    <HandleServerError 
      :error="serverFailed"
      :show="showError"
      @close="hideError"
    />

  </div>
</template>

<script>
import KategoriAdd from '@/views/master-kategori/KategoriAdd.vue';
import KategoriEdit from '@/views/master-kategori/KategoriEdit.vue';
import HandleServerError from '@/components/element/HandleServerError.vue'
import { RepositoryAPI } from '@/api/repository_api';
const callApi  = RepositoryAPI.get('auth_admin');

export default {
  name: 'master-approval',
  data() {
    return{
      loading: false,
      loadingDelete: false,
      lengthData: 0,
      userProfile: null,
      rsvpData: null,
      showAddModal: false,
      masterApproval: [],
      totalData: 0,
      payload: {
        pageNumber: 1,
        pageSize: 10,
        sortBy:'created',
        sortDirection: 'DESC'
      },
      editModal: false,
      editItem: {},
      paginationOpt: {
        chunk: 5
      },
      serverFailed: {},
      showError: false,
      modalDetail: false,
      detailToko: {
        namaToko: null,
        keterangan: null,
        provinsi: null,
        kota: null,
        kecamatan: null,
        alamat: null,
        kodePos: null,
        bank: null,
        noRekening: null,
        namaPemilikRekening: null
      }
    }
  },
  components: {
    KategoriAdd,
    KategoriEdit,
    HandleServerError
  },
  mounted() {
    this.fetchData();
  },
  methods:{
    async fetchData() {
      this.loading = true;
      try {
          const res = await callApi.getApprovalTokoAll(this.payload);
          this.masterApproval = res.data.data;
          this.totalData = res.data.data.length;
          this.loading = false;
      } catch(e) {
          console.log(e)
      }
    },
    approveThis(value) {
      this.$swal.fire({
        title: 'Setujui toko ini?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#c00434',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, Setujui!',
        cancelButtonText: 'Batal'
      }).then(async(result) => {
        if (result.isConfirmed) {
          this.loadingDelete = true;
          try {
            await callApi.approveToko(value.tokoTempId);
            this.$swal.fire(
              'Disetujui!',
              'Toko berhasil disetujui.',
              'success'
            )
            this.loadingDelete = false;
            this.fetchData();
          } catch(e) {
            console.log(e)
          }
        }
      })
    },
    pagination(page) {
      this.payload.pageNumber = page;
      this.fetchData();
    },
    serverError(value) {
      this.serverFailed = value;
      this.showError = value.show;
    },
    hideError(value) {
      this.showError = value;
    },
    hideModalDetail() {
      this.modalDetail = false;
    },
    openDetail(value){
      this.modalDetail = true;
      this.detailToko = {
        namaToko: value.namaToko,
        keterangan: value.keterangan,
        provinsi: value.provinsi,
        kota: value.kota,
        kecamatan: value.kecamatan,
        alamat: value.alamat,
        kodePos: value.kodePos,
        bank: value.bank,
        noRekening:value.noRekening,
        namaPemilikRekening: value.namaPemilikRekening
      }
    }
  },
  computed: {
  }
}
</script>
<style lang="scss" scoped>
.table{
  th{
    text-align: left;
  }
  td{
    text-align: left;
    .loading{
      color: #c00434;
      text-align: center;
    }
    .total-label{
      text-align: left !important;
      font-size: 16px;
      font-weight: 700;
    }
    .total-guest{
      text-align: left !important;
      font-size: 16px;
      font-weight: 700;
    }
  }
}
.gambarIcon {
  img {
    height: 40px;
    width: auto;
  }
}
.pagination-section {
    width: 370px;
    margin:0 auto;
    padding-top: 30px;
}
.detailToko {
  width: 100%;
}
</style>
