/* eslint-disable */
import Vue from 'vue';
import ShardsVue from 'shards-vue';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Pagination from 'vue-pagination-2';
import VueFusionCharts from 'vue-fusioncharts';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
//import the theme
// import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion'

// Styles
import 'bootstrap/dist/css/bootstrap.css';
import '@/scss/shards-dashboards.scss';
import '@/assets/scss/date-range.scss';

// Core
import App from './App.vue';
import router from './router';
import store from './store'
import VueApexCharts from 'vue-apexcharts'

// Layouts
// import DefaultLogin from '@/layouts/DefaultLogin.vue';

ShardsVue.install(Vue);

// Vue.component('default-login', DefaultLogin);
Vue.component();
Vue.use(VueSweetalert2);
Vue.component('pagination', Pagination);
Vue.use(VueFusionCharts, FusionCharts, Charts);
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts)

Vue.config.productionTip = false;
Vue.prototype.$eventHub = new Vue();

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
