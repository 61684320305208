<template>
    <d-card class="card-small mb-4">
        <!-- Card Header -->
        <d-card-header class="border-bottom">
          <h6 class="m-0">{{ title }}</h6>
        </d-card-header>
        <d-card-body>

          <!-- Alert -->
          <d-alert :show="successSave" dismissible theme="success">Data berhasil disimpan</d-alert>

          <d-list-group flush>
            <d-list-group-item class="p-3">
              <d-row>
                <d-col md="12" v-if="loadingData">
                  <div class="loading text-center">
                    <i class="fa fa-spinner fa-spin fa-2x"></i>
                  </div>
                </d-col>
                <d-col md="12" v-else>
                  <strong class="text-muted d-block mb-2">Mempelai Wanita</strong>
                  <d-form>
                    <d-form-row>
                      <d-col md="6" class="form-group">
                        <label for="feEmailAddress">Nama Depan</label>
                        <d-input type="text" placeholder="Nama Depan" v-model="param.first_name_women" />
                      </d-col>
                      <d-col md="6" class="form-group">
                        <label for="feEmailAddress">Nama Belakang</label>
                        <d-input type="text" placeholder="Nama Belakang" v-model="param.last_name_women"/>
                      </d-col>
                    </d-form-row>
                    <d-form-row>
                      <d-col md="12" class="form-group">
                        <label for="feEmailAddress">Akun Instagram</label>
                        <d-input type="text" placeholder="Contoh: jaditamuku" v-model="param.instagram_women" />
                      </d-col>
                    </d-form-row>
                    <strong class="text-muted d-block mb-2">Orang Tua Wanita</strong>
                    <d-form-row>
                      <d-col md="6" class="form-group">
                        <label for="feEmailAddress">Nama Depan Ayah</label>
                        <d-input type="text" placeholder="Nama Depan Ayah" v-model="param.first_name_dad_women"/>
                      </d-col>
                      <d-col md="6" class="form-group">
                        <label for="feEmailAddress">Nama Belakang Ayah</label>
                        <d-input type="text" placeholder="Nama Belakang Ayah" v-model="param.last_name_dad_women" />
                      </d-col>
                    </d-form-row>
                    <d-form-row>
                      <d-col md="6" class="form-group">
                        <label for="feEmailAddress">Nama Depan Ibu</label>
                        <d-input type="text" placeholder="Nama Depan Ibu" v-model="param.first_name_mom_women" />
                      </d-col>
                      <d-col md="6" class="form-group">
                        <label for="feEmailAddress">Nama Belakang Ibu</label>
                        <d-input type="text" placeholder="Nama Belakang Ibu" v-model="param.last_name_mom_women" />
                      </d-col>
                    </d-form-row>

                    <strong class="text-muted d-block mb-2">Mempelai Pria</strong>
                    <d-form-row>
                      <d-col md="6" class="form-group">
                        <label for="feEmailAddress">Nama Depan</label>
                        <d-input type="text" placeholder="Nama Depan" v-model="param.first_name_man" />
                      </d-col>
                      <d-col md="6" class="form-group">
                        <label for="feEmailAddress">Nama Belakang</label>
                        <d-input type="text" placeholder="Nama Belakang" v-model="param.last_name_man" />
                      </d-col>
                    </d-form-row>
                    <d-form-row>
                      <d-col md="12" class="form-group">
                        <label for="feEmailAddress">Akun Instagram</label>
                        <d-input type="text" placeholder="Contoh: jaditamuku" v-model="param.instagram_man" />
                      </d-col>
                    </d-form-row>
                    <strong class="text-muted d-block mb-2">Orang Tua Pria</strong>
                    <d-form-row>
                      <d-col md="6" class="form-group">
                        <label for="feEmailAddress">Nama Depan Ayah</label>
                        <d-input type="text" placeholder="Nama Depan Ayah" v-model="param.first_name_dad_man" />
                      </d-col>
                      <d-col md="6" class="form-group">
                        <label for="feEmailAddress">Nama Belakang Ayah</label>
                        <d-input type="text" placeholder="Nama Belakang Ayah" v-model="param.last_name_dad_man" />
                      </d-col>
                    </d-form-row>
                    <d-form-row>
                      <d-col md="6" class="form-group">
                        <label for="feEmailAddress">Nama Depan Ibu</label>
                        <d-input type="text" placeholder="Nama Depan Ibu" v-model="param.first_name_mom_man" />
                      </d-col>
                      <d-col md="6" class="form-group">
                        <label for="feEmailAddress">Nama Belakang Ibu</label>
                        <d-input type="text" placeholder="Nama Belakang Ibu" v-model="param.last_name_mom_man"/>
                      </d-col>
                    </d-form-row>
                  </d-form>
                </d-col>
              </d-row>
            </d-list-group-item>
          </d-list-group>
        </d-card-body>
        <d-card-footer>
            <d-row>
                <d-col>
                    <d-button type="submit" theme="light" @click="toBack">Sebelumnya</d-button>
                </d-col>
                <d-col>
                  <div class="text-right">
                      <d-button type="submit" theme="warning" v-if="loading">
                        <i class="fa fa-spinner fa-spin"></i> Loading
                      </d-button>
                      <d-button 
                        type="submit" 
                        theme="warning"
                        @click="saveData" 
                        v-else
                      >
                        Simpan
                      </d-button>
                  </div>
                  <!-- <div class="text-right" v-else>
                      <d-button type="submit" theme="warning" @click="toNext">Selanjutnya</d-button>
                  </div> -->
                </d-col>
            </d-row>
        </d-card-footer>
    </d-card>
</template>

<script>
import { RepositoryAPI } from '@/api/repository_api';
const callApi  = RepositoryAPI.get('auth_jaditamuku');
export default {
  name: 'basic-info',
  props: {
    /**
     * The component title.
     */
    title: {
      type: String,
      default: 'Informasi Mempelai',
    },
  },
  data() {
    return {
      checkData: false,
      loadingData: false,
      param: {
        first_name_women: null,
        last_name_women: null,
        first_name_man: null,
        last_name_man: null,
        first_name_dad_women: null,
        last_name_dad_women: null,
        first_name_mom_women: null,
        last_name_mom_women: null,
        first_name_dad_man: null,
        last_name_dad_man: null,
        first_name_mom_man: null,
        last_name_mom_man: null,
        instagram_women: null,
        instagram_man: null,
      },
      loading: false,
      message: null,
      successSave: false,
    }
  },

  mounted() {
    this.getData();
  },

  methods: {
    toNext() {
      // this.$emit('openPage', 'BasicInfo');
      alert('belum ada');
    },

    toBack() {
      this.$emit('openPage', 'PilihTemplate');
    },

    async getData() {
      this.loadingData = true;
      try {
        const data = await callApi.getCouples();
        this.checkData = data.data.succes;
        if (data.data.succes === true) {
          this.param.first_name_women = data.data.response.first_name_women;
          this.param.last_name_women = data.data.response.last_name_women;
          this.param.first_name_man = data.data.response.first_name_ma;
          this.param.last_name_man = data.data.response.last_name_man;
          this.param.first_name_dad_women = data.data.response.first_name_dad_women;
          this.param.last_name_dad_women = data.data.response.last_name_dad_women;
          this.param.first_name_mom_women = data.data.response.first_name_mom_women;
          this.param.last_name_mom_women = data.data.response.last_name_mom_women;
          this.param.first_name_dad_man = data.data.response.first_name_dad_man;
          this.param.last_name_dad_man = data.data.response.last_name_dad_man;
          this.param.first_name_mom_man = data.data.response.first_name_mom_man;
          this.param.last_name_mom_man = data.data.response.last_name_mom_man;
          this.param.instagram_women = data.data.response.instagram_women;
          this.param.instagram_man = data.data.response.instagram_man;
        }
        this.loadingData = false;
      } catch(e) {
        this.loadingData = false;
        console.log(e);
      }
    },

    async saveData() {
      this.loading = true;
      try{
        const data = await callApi.postCouples(this.param);
        if(data.status == 200) {
          this.loading = false;
          this.successSave = true;
          this.getData();
        }
      } catch(e) {
        console.log(e);
        this.loading = false;
        this.message = 'Gagal Menyimpan Data'
      }

    }
  }
};
</script>

<style lang="scss" scoped>
.loading{
  color: #c00434;
  text-align: center;
}
</style>