<template>
<d-card>
    <div class="lineCharts">
        <h5>{{ title }}</h5>
        <apexchart :height="height" width="100%" type="line" :options="chartOptions" :series="series"></apexchart>
    </div>
</d-card>
</template>

<script>

export default {
    name: 'LineChartsData',
    props: {
        title: {
            type: String,
            default: ''
        },
        height: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            chartOptions: {
                chart: {
                    id: 'vuechart-example',
                    width: "100%",
                    height: 500,
                },
                xaxis: {
                    categories: ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober"]
                }
            },
            series: [{
                name: 'series-1',
                data: [23, 25, 30, 40, 45, 50, 49, 60, 70, 11]
            }],
            legend: {
                position: "right",
                verticalAlign: "top",
                containerMargin: {
                    left: 35,
                    right: 60
                }
            },
            responsive: [
                {
                breakpoint: 1000,
                options: {
                    plotOptions: {
                    bar: {
                        horizontal: false
                    }
                    },
                    legend: {
                        position: "bottom"
                    }
                }
                }
            ],
        };
    },
}
</script>
<style lang="scss" scoped>
.lineCharts {
    padding: 15px;
}
</style>
