<template>
<div class="main-content-container container-fluid px-4">
    <!-- Page Header -->
    <div class="page-header row no-gutters py-4">
      <div class="col-12 col-sm-4 text-center text-sm-left mb-0">
        <h3 class="page-title">Home</h3>
      </div>
    </div>
    <div class="row ">
        <div class="col-12 text-center text-sm-left mb-0">
            <d-alert theme="success" show>Selamat Datang {{userDetail.namaUser}}</d-alert>
        </div>
    </div>
    <div class="row py-4">
        <div class="col-6">
          <LineChartsData title="Jumlah Pengguna" height="300px" />
        </div>
        <div class="col-6">
          <BarChartsData title="Jumlah Transaksi" height="300px" />
        </div>
    </div>
</div>
</template>

<script>
import { RepositoryAPI } from '@/api/repository_api';
const callApi  = RepositoryAPI.get('auth_admin');
import LineChartsData from '@/components/charts/LineChartsData.vue'
import BarChartsData from '@/components/charts/BarChartsData.vue'

export default {
  name: 'home-admin',
  data() {
    return {
      userDetail: {}
    }
  },
  components: {
    LineChartsData,
    BarChartsData
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const res = await callApi.getUserProfileDetail();
        this.userDetail = res.data.data;
      } catch(e) {
        console.log(e)
      }
    }
  }
}
</script>