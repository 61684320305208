<template>
<div>
    <d-list-group flush>
        <d-list-group-item>
            <d-row>
                <d-col md="12" v-if="loading">
                    <div class="loading text-center">
                        <i class="fa fa-spinner fa-spin fa-2x"></i>
                    </div>
                </d-col>
                <d-col md="12" v-else>
                    <d-col class="form-group">
                        <label for="feEmailAddress">Master Kategori <span class="load-master" v-if="loadMaster">Loading...</span></label>
                        <d-form-select v-model="payload.kategoriId" :options="masterKategori" />
                    </d-col>
                    <d-col class="form-group">
                        <label for="feEmailAddress">Nama Sub Kategori</label>
                        <d-input type="text" placeholder="Nama Sub Kategori" v-model="payload.namaSubKategori"/>
                    </d-col>
                    <d-col class="form-group">
                        <label for="feEmailAddress">Keterangan</label>
                        <d-textarea placeholder="Keterangan" v-model="payload.keterangan"></d-textarea>
                    </d-col>
                    <d-col class="form-group text-right aliansi-button">
                        <d-button type="submit" class="btn-accent" :disabled="disabledBtn" @click="createCategory">Simpan</d-button>
                        <d-button theme="danger" @click="handleClose">Batal</d-button>
                    </d-col>
                </d-col>
            </d-row>
        </d-list-group-item>
    </d-list-group>
</div>
</template>
<script>
import { RepositoryAPI } from '@/api/repository_api';
const callApi  = RepositoryAPI.get('auth_admin');

export default {
    data() {
        return {
            loading: false,
            showModal: false,
            disabledBtn: true,
            payload: {
                kategoriId: null,
                keterangan: "",
                namaSubKategori: ""
            },
            payloadMaster: {
                pageNumber: 1,
                pageSize: 50,
                sortBy:'created',
                sortDirection: 'DESC'
            },
            masterKategori: [
                { value: null, text: 'Pilih master kategori' },
            ],
            loadMaster: false
        }
    },
    mounted() {
        this.fetchMaster();
    },
    methods: {
        async fetchMaster() {
            this.loadMaster = true;
            try {
                const res = await callApi.getMasterKategoriAll(this.payloadMaster);
                const data = res.data.data.data;
                data.forEach((item) => {
                    const list = {
                        value: item.kategoriId,
                        text: item.namaKategori
                    };
                    this.masterKategori.push(list);
                });
                this.loadMaster = false;
            } catch(e) {
                console.log(e)
            }
        },
        async createCategory() {
            this.loading = true;
            try {
                await callApi.createSubKategori(this.payload);
                this.loading = false;
                this.$swal.fire(
                    'Berhasil!',
                    'Data berhasil ditambahkan.',
                    'success'
                )
                this.handleClose();
                this.$emit('success');
            } catch(e) {
                console.log(e);
            }
        },
        handleClose() {
            this.showModal = false;
            this.$emit('close', false);
        }
    },
    watch: {
        show: function(val){
            this.showModal = val;
            this.$emit('close', false);
        },
        payload: {
            deep: true,
            handler: function(value) {
                if (value.kategoriId !== null && value.keterangan !== ""  && value.namaSubKategori !== "" && value.keterangan.length > 5 && value.namaSubKategori.length > 3){
                    this.disabledBtn = false;
                } else {
                    this.disabledBtn = true;
                }
            }
        }
    }
}
</script>
<style scoped>
.load-master {
    font-size: 10px;
    color: #ddd;
}
</style>