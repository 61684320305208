<template>
  <div class="main-content-container container-fluid px-4">
    <!-- Page Header -->
    <div class="page-header row no-gutters py-4">
      <div class="col-12 col-sm-4 text-center text-sm-left mb-0">
        <h3 class="page-title">Master Approval Produk</h3>
      </div>
    </div>

    <!-- Default Light Table -->
    <div class="row">
      <div class="col">
        <div class="card card-small mb-4">
          <div class="card-header border-bottom header-table">
            <h6 class="m-0">Data Toko</h6>
          </div>
          <div class="card-body p-0 pb-3 text-center" style="overflow-x:auto;">
            <table class="table mb-0">
              <thead class="bg-light">
                <tr>
                  <th scope="col" class="border-0" >Nama Produk</th>
                  <th scope="col" class="border-0" >Deskripsi</th>
                  <th scope="col" class="border-0">Aksi</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="loading">
                  <td colspan="5">
                    <div class="loading">
                      <i class="fa fa-spinner fa-spin fa-2x"></i>
                    </div>
                  </td>
                </tr>
                <tr v-else-if="masterApproval.length == 0">
                  <td colspan="6" style="text-align: center;">
                    Belum ada data
                  </td>
                </tr>
                <tr v-for="(item, index) in masterApproval" :key="index" v-else>
                  <td>{{ item.namaProduk }}</td>
                  <td>{{ item.deskripsi}}</td>
                  <td>
                    <div class="aliansi-button">
                      <button 
                        class="btn btn-outline-purple" 
                        @click="showModalDetail(item)"
                        :disabled="loadingDelete"
                      >
                        Detail Produk
                      </button>
                      <button 
                        class="btn btn-outline-purple" 
                        @click="approveThis(item)"
                        :disabled="loadingDelete"
                      >
                        <i class="material-icons">done</i> Setujui Produk
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="pagination-section">
              <pagination 
                :records="totalData" 
                v-model="payload.pageNumber" 
                :per-page="payload.pageSize"
                :options="paginationOpt" 
                @paginate="pagination" 
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <d-modal v-if="showAddModal" @close="handleClose" class="modal-index" centered>
        <d-modal-header>
            <d-modal-title>Tambah Master Kategori</d-modal-title>
        </d-modal-header>
        <d-modal-body>
            <KategoriAdd 
              @close="handleClose"
              @success="fetchData"
            />
        </d-modal-body>
    </d-modal>

    <d-modal v-if="editModal" @close="hideEditModal" class="modal-index" centered>
        <d-modal-header>
            <d-modal-title>Edit Master Kategori</d-modal-title>
        </d-modal-header>
        <d-modal-body>
            <KategoriEdit
              @close="hideEditModal"
              @success="fetchData"
              :categoryItem="editItem"
              @error="serverError"
            />
        </d-modal-body>
    </d-modal>

    <d-modal v-if="modalDetail" @close="hideModalDetail" class="modal-index" centered>
        <d-modal-header>
            <d-modal-title>Detail Produk</d-modal-title>
        </d-modal-header>
        <d-modal-body>
          <div class="loadingDetail" v-if="loadingDetail">
            <i class="fa fa-spinner fa-spin fa-2x"></i>
          </div>
          <div class="detailProduk" v-else>
            <tr>
              <td width="150">Nama Produk</td>
              <td>:</td>
              <td>{{ detailProduct.namaProduk }}</td>
            </tr>
            <tr>
              <td width="150">Kategori</td>
              <td>:</td>
              <td>{{ detailProduct.namaKategori }}</td>
            </tr>
            <tr>
              <td width="150">Subategori</td>
              <td>:</td>
              <td>{{ detailProduct.namaSubKategori }}</td>
            </tr>
            <tr>
              <td width="150">Harga Normal</td>
              <td>:</td>
              <td>Rp {{ detailProduct.hargaNormal }}</td>
            </tr>
            <tr>
              <td width="150">Harga Promo</td>
              <td>:</td>
              <td>Rp {{ detailProduct.hargaPromo }}</td>
            </tr>
            <tr>
              <td width="150">Berat (gram)</td>
              <td>:</td>
              <td>{{ detailProduct.beratGram }}</td>
            </tr>
            <tr>
              <td width="150">Stok</td>
              <td>:</td>
              <td>{{ detailProduct.qtyStock }}</td>
            </tr>
            <tr>
              <td width="150">Deskripsi</td>
              <td>:</td>
              <td>{{ detailProduct.deskripsi }}</td>
            </tr>
            <tr>
              <td width="150">Jumlah Terjual</td>
              <td>:</td>
              <td>{{ detailProduct.qtyTerjual }}</td>
            </tr>
            <tr>
              <td width="150">Nama Toko</td>
              <td>:</td>
              <td>{{ detailProduct.namaToko }}</td>
            </tr>
            <tr>
              <td width="150">Image</td>
              <td>:</td>
              <td>
                <div v-if="detailProduct.listPicture.length == 0">No Image</div>
                <div v-else>
                  <div class="imageProducts" v-for="(item, index) in detailProduct.listPicture" :key="index">
                    <img :src="item.url" >
                  </div>
                </div>
              </td>
            </tr>
          </div>
        </d-modal-body>
    </d-modal>

    <HandleServerError 
      :error="serverFailed"
      :show="showError"
      @close="hideError"
    />

  </div>
</template>

<script>
import KategoriAdd from '@/views/master-kategori/KategoriAdd.vue';
import KategoriEdit from '@/views/master-kategori/KategoriEdit.vue';
import HandleServerError from '@/components/element/HandleServerError.vue'
import { RepositoryAPI } from '@/api/repository_api';
const callApi  = RepositoryAPI.get('auth_admin');

export default {
  name: 'master-approval',
  data() {
    return{
      loading: false,
      loadingDelete: false,
      lengthData: 0,
      userProfile: null,
      rsvpData: null,
      showAddModal: false,
      masterApproval: [],
      totalData: 0,
      payload: {
        pageNumber: 1,
        pageSize: 10,
        sortBy:'created',
        sortDirection: 'DESC'
      },
      editModal: false,
      editItem: {},
      paginationOpt: {
        chunk: 5
      },
      serverFailed: {},
      showError: false,
      modalDetail: false,
      detailProduct: {
        approvedBy: null,
        beratGram: null,
        deskripsi: null,
        hargaNormal: null,
        hargaPromo: null,
        isHargaPromo: null,
        kategoriId: null,
        listPicture: [],
        namaKategori: null,
        namaProduk: null,
        namaSubKategori: null,
        namaToko: null,
        produkId: null,
        qtyStock: null,
        qtyTerjual: null,
        status: null,
        subKategoriId: null,
        tokoId: null
      },
      loadingDetail: false
    }
  },
  components: {
    KategoriAdd,
    KategoriEdit,
    HandleServerError
  },
  mounted() {
    this.fetchData();
  },
  methods:{
    async fetchData() {
      this.loading = true;
      try {
          const res = await callApi.getApprovalProdukAll(this.payload);
          this.masterApproval = res.data.data;
          this.totalData = res.data.data.length
          this.loading = false;
      } catch(e) {
          console.log(e)
      }
    },
    approveThis(value) {
      this.$swal.fire({
        title: 'Setujui produk ini?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#c00434',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, Setujui!',
        cancelButtonText: 'Batal'
      }).then(async(result) => {
        if (result.isConfirmed) {
          this.loadingDelete = true;
          try {
            await callApi.approveProduk(value.approvalId);
            this.$swal.fire(
              'Disetujui!',
              'Produk berhasil disetujui.',
              'success'
            )
            this.loadingDelete = false;
            this.fetchData();
          } catch(e) {
            console.log(e)
          }
        }
      })
    },
    pagination(page) {
      this.payload.pageNumber = page;
      this.fetchData();
    },
    serverError(value) {
      this.serverFailed = value;
      this.showError = value.show;
    },
    hideError(value) {
      this.showError = value;
    },
    async showModalDetail(value) {
      this.modalDetail = true;
      this.loadingDetail = true;
      try {
        const produkId = value.produkId;
        const res = await callApi.detailProduct(produkId);
        this.detailProduct = res.data.data;
        this.loadingDetail = false;
      } catch(e) {
        console.error(e);
      }
      
    },
    hideModalDetail() {
      this.modalDetail = false;
    }
  },
  computed: {
  }
}
</script>
<style lang="scss" scoped>
.table{
  th{
    text-align: left;
  }
  td{
    text-align: left;
    .loading{
      color: #c00434;
      text-align: center;
    }
    .total-label{
      text-align: left !important;
      font-size: 16px;
      font-weight: 700;
    }
    .total-guest{
      text-align: left !important;
      font-size: 16px;
      font-weight: 700;
    }
  }
}
.gambarIcon {
  img {
    height: 40px;
    width: auto;
  }
}
.pagination-section {
    width: 370px;
    margin:0 auto;
    padding-top: 30px;
}

.loadingDetail {
  width: 100%;
  text-align: center;
  padding: 50px 0px;
}
.detailProduk {

}
.imageProducts {
  width: 200px;
  height: 200px;
  position: relative;
  overflow: hidden;
  img {
    width: 100%;
    padding: 5px;
    height: 230px;
    object-fit: cover;
  }
}
</style>
