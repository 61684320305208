<template>
  <d-container fluid class="main-content-container px-4">
    <!-- Page Header -->
    <d-row no-gutters class="page-header py-4">
      <d-col col sm="4" class="text-center text-sm-left mb-4 mb-sm-0">
        <h3 class="page-title">Profil</h3>
      </d-col>
    </d-row>

    <!-- Content -->
    <d-row>
      <d-col lg="12">
        <upl-user-account-details 
          title="Detail Akun"
        />
      </d-col>
    </d-row>
  </d-container>
</template>

<script>
import UserAccountDetails from '@/components/user-profile-lite/UserAccountDetails.vue';

export default {
  name: 'profil',
  components: {
    uplUserAccountDetails: UserAccountDetails,
  },
};
</script>

