<template>
  <d-card class="card-small mb-4">

    <!-- Card Header -->
    <d-card-header class="border-bottom">
      <h6 class="m-0">{{ title }}</h6>
    </d-card-header>

    <d-list-group flush>
      <d-list-group-item class="p-3">
        <d-row>
          <d-col>
            <d-form>
              <!-- First Name -->
              <d-col md="12" class="form-group">
                <label for="feFirstName">Nama Lengkap</label>
                <d-form-input type="text" id="feFirstName" placeholder="Nama Lengkap" v-model="userDetail.namaUser" />
              </d-col>
              <!-- Email -->
              <d-col md="12" class="form-group">
                <label for="feEmail">Email</label>
                <d-form-input type="email" id="feEmail" placeholder="Email" v-model="userDetail.email" />
              </d-col>

              <!-- Address -->
              <d-col md="12" class="form-group">
                <label for="feInputAddress">Nomor HP</label>
                <d-form-input type="number" id="feInputAddress" v-model="userDetail.nomorHp" />
              </d-col>
              <!-- Description -->
              <d-col md="12" class="form-group">
                <label for="feDescription">Keterangan</label>
                <d-textarea name="feDescription" rows="5"
                  v-model="userDetail.keterangan"
                  :value.prop="userDetail.keterangan"
                ></d-textarea>
              </d-col>
              <!-- <d-button type="submit" class="btn-accent">Update Account</d-button> -->

            </d-form>
          </d-col>
        </d-row>
      </d-list-group-item>
    </d-list-group>
  </d-card>
</template>

<script>
import { RepositoryAPI } from '@/api/repository_api';
const callApi  = RepositoryAPI.get('auth_admin');

export default {
  name: 'user-account-details',
  props: {
    title: {
      type: String,
      default: 'Account Details',
    },
  },
  data() {
    return {
      userDetail: {}
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const res = await callApi.getUserProfileDetail();
        this.userDetail = res.data.data;
        console.log(this.userDetail)
      } catch(e) {
        console.log(e)
      }
    }
  }
};
</script>
