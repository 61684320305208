<template>
<div>
    <d-list-group flush>
        <d-list-group-item>
            <d-row>
                <d-col md="12" v-if="loading">
                    <div class="loading text-center">
                        <i class="fa fa-spinner fa-spin fa-2x"></i>
                    </div>
                </d-col>
                <d-col md="12" v-else>
                    <div class="imageEdit text-center">
                        <img :src="payload.gambarProfile" />
                    </div>
                    <d-col class="form-group">
                        <label for="feEmailAddress">Email</label>
                        <d-input type="email" placeholder="Email" v-model="payload.email"/>
                    </d-col>
                    <d-col class="form-group">
                        <label for="feEmailAddress">Password</label>
                        <d-input type="password" placeholder="Password" v-model="payload.password"/>
                    </d-col>
                    <d-col class="form-group">
                        <label for="feEmailAddress">Nama Pengguna</label>
                        <d-input type="text" placeholder="Nama pengguna" v-model="payload.namaUser"/>
                    </d-col>
                    <d-col class="form-group">
                        <label for="feEmailAddress">Gambar Profil</label>
                        <d-input type="text" placeholder="Gambar Profil" v-model="payload.gambarProfile"/>
                    </d-col>
                    <d-col>
                        <label for="feEmailAddress">Nomor HP</label>
                        <d-input type="number" placeholder="Nomor HP" v-model="payload.nomorHp"/>
                    </d-col>
                    <d-col class="form-group">
                        <label for="feEmailAddress">Keterangan</label>
                        <d-textarea 
                            placeholder="Keterangan" 
                            v-model="payload.keterangan"
                            :value.prop="payload.keterangan"
                        >
                        </d-textarea>
                    </d-col>
                    <d-col class="form-group text-right aliansi-button">
                        <d-button type="submit" class="btn-accent" :disabled="disabledBtn" @click="editData">Simpan</d-button>
                        <d-button theme="danger" @click="handleClose">Batal</d-button>
                    </d-col>
                </d-col>
            </d-row>
        </d-list-group-item>
    </d-list-group>
</div>
</template>

<script>
import { RepositoryAPI } from '@/api/repository_api';
const callApi  = RepositoryAPI.get('auth_admin');

export default {
    props: {
        userItem: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            loading: false,
            disabledBtn: true,
            payload: {
                email: "",
                gambarProfile: "",
                keterangan: "",
                namaRole: "ADMIN",
                namaUser: "",
                nomorHp: "",
                password: "",
                userId: ""
            },
            error: {}
        }
    },
    mounted() {
        this.payload = this.$props.userItem;
    },
    methods: {
        handleClose() {
            this.$emit('close', false);
        },
        validateEmail(value){      
            const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
            return regex.test(value);
        }, 
        async editData(){
            this.loading = true;
            try {
                const res = await callApi.editUser(this.payload);
                this.loading = true;
                    this.$swal.fire(
                        'Berhasil!',
                        'Data berhasil diubah.',
                        'success'
                    )
                    this.loading = false;
                    this.handleClose();
                    this.$emit('success');
            } catch(e) {
                console.log(e)
                this.error = e.response.data;
                this.loading = false;
                this.handleClose();
                this.$emit('error', {
                    data: this.error,
                    show: true
                });
            }
        },
    },
    watch: {
        payload: {
            deep: true,
            handler: function(value) {
                if (this.validateEmail(value.email) == true 
                    && value.namaUser !== "" 
                    && value.gambarProfile !== ""
                    && value.nomorHp !== ""
                    && value.keterangan !== ""
                    && value.namaUser.length > 3 
                    && value.gambarProfile.length > 5
                    && value.nomorHp.length > 6
                    && value.keterangan.length > 5
                )
                {
                    this.disabledBtn = false;
                } else {
                    this.disabledBtn = true;
                }
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .imageEdit {
        margin-bottom: 20px;
        img {
            height: 150px;
            width: auto;
        }
    }
</style>
