<template>
  <!-- <component :is="login">
    <router-view/>
  </component> -->
  <div>
    <router-view/>
  </div>
</template>

<script>
export default {
  
};
</script>
