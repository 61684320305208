<template>
<div div class="login">
    <div class="container d-flex h-100">   
        <div class="row align-self-center w-100">
            <div class="col-md-4 mx-auto">
                
                <!-- Card -->
                <div class="card align-middle">

                    <!-- Card body -->
                    <div class="card-body">

                        <!-- Default form subscription -->
                        <div>
                            <div class="logo-login">
                                <img :src="logo"/>
                                <div class="title">Olebsai</div>
                            </div>
                            <div class="sign-in-error" v-if="messageError">{{messageError}}</div>
                            <!-- Default input name -->
                            <label for="defaultFormCardNameEx" class="grey-text font-weight-light">Email</label>
                            <input type="email" class="form-control" placeholder="email" v-model="param.email">

                            <br>

                            <!-- Default input email -->
                            <label for="defaultFormCardEmailEx" class="grey-text font-weight-light">Password</label>
                            <input type="password" class="form-control" placeholder="password" v-model="param.password">

                            <div class="text-center py-4 mt-3">
                                <button class="btn btn-outline-purple" v-if="loading"><i class="fa fa-spinner fa-spin"></i> Loading</button>
                                <button class="btn btn-outline-purple" :disabled="disable" @click="login" v-else>Sign In<i class="far fa-paper-plane ml-2"></i></button>
                            </div>
                        </div>
                        <!-- Default form subscription -->

                    </div>
                    <!-- Card body -->

                </div>
                <!-- Card -->

            </div>
        </div>
    </div>
</div>
</template>
<script lang="js">
import logo from '@/assets/images/logo-oleb.png'
import Cookies from "js-cookie";
import { RepositoryAPI } from '@/api/repository_api';
const callApi  = RepositoryAPI.get('no_auth');
export default {
    data() {
      return {
          logo: logo,
          data: null,
          param: {
              email: null,
              password: null,
          },
          loading: false,
          messageError: null,
          disable: true,
      }  
    },
    mounted(){
        this.validateToken();
    },
    watch: {
        param: {
            deep: true,
            handler: function(value){
                if (this.validateEmail(value.email) == true && !this.isEmptyOrSpaces(value.password)) {
                    this.disable = false;
                } else {
                    this.disable = true;
                }
            }
        }
    },
    methods: {
        async login() {
            this.loading = true;
            try {
                const data = await callApi.signIn(this.param);
                if (data.data.status === 'OK') {
                    this.loading = false;
                    let token = data.data.data.token;
                    Cookies.set("token_", token);
                    this.$router.push('/home');
                    location.reload();
                } else {
                    this.loading = false;
                    this.messageError = data.data.message;
                }
            } catch(e) {
                this.messageError = 'Sign In gagal';
                this.loading = false;
            }
        },
        validateToken() {
            const token = Cookies.getJSON("token_");
            if (token) {
                this.$router.push('/home');
            }
        },
        validateEmail(value){      
            const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
            return regex.test(value);
        }, 
        isEmptyOrSpaces(value) {
            return value === null || value.match(/^ *$/) !== null;
        }
    }
}
</script>
<style lang="scss" scoped>
.align-middle {
    vertical-align: middle !important;
    margin-top: 30%;
}
.btn-outline-purple {
    background: #c00434;
    color: #fff;
    &:disabled{
        color: #000;
        background: #cf768c;
        cursor: not-allowed;
    }
}
.logo-login {
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
    img{
        width: 70px;
    }
    .title {
        font-size: 27px;
        color: #c00434;
    }
}
.card-body{
    .h3{
        font-size: 16px;
    }
    .sign-in-error{
        background-color: #f37c73;
        text-align: center;
        color: #fff;
        font-weight: 300;
        margin-bottom: 15px;
        padding: 5px 20px;
    }
}
</style>