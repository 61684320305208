<template>
<div>
    <d-list-group flush>
        <d-list-group-item>
            <d-row>
                <d-col md="12" v-if="loading">
                    <div class="loading text-center">
                        <i class="fa fa-spinner fa-spin fa-2x"></i>
                    </div>
                </d-col>
                <d-col md="12" v-else>
                    <d-col class="form-group">
                        <label for="feEmailAddress">Nama Role</label>
                        <d-input type="text" placeholder="Nama Role" v-model="payload.namaRole"/>
                    </d-col>
                    <d-col class="form-group">
                        <label for="feEmailAddress">Keterangan</label>
                        <d-textarea placeholder="Keterangan" v-model="payload.keterangan"></d-textarea>
                    </d-col>
                    <d-col class="form-group text-right aliansi-button">
                        <d-button type="submit" class="btn-accent" :disabled="disabledBtn" @click="createCategory">Simpan</d-button>
                        <d-button theme="danger" @click="handleClose">Batal</d-button>
                    </d-col>
                </d-col>
            </d-row>
        </d-list-group-item>
    </d-list-group>
</div>
</template>
<script>
import { RepositoryAPI } from '@/api/repository_api';
const callApi  = RepositoryAPI.get('no_auth');

export default {
    data() {
        return {
            loading: false,
            showModal: false,
            disabledBtn: true,
            payload: {
                keterangan: "",
                namaRole: ""
            }
        }
    },
    methods: {
        async createCategory() {
            this.loading = true;
            try {
                await callApi.createRole(this.payload);
                this.loading = false;
                this.$swal.fire(
                    'Berhasil!',
                    'Data berhasil ditambahkan.',
                    'success'
                )
                this.handleClose();
                this.$emit('success');
            } catch(e) {
                console.log(e);
            }
        },
        handleClose() {
            this.showModal = false;
            this.$emit('close', false);
        }
    },
    watch: {
        show: function(val){
            this.showModal = val;
            this.$emit('close', false);
        },
        payload: {
            deep: true,
            handler: function(value) {
                if (value.keterangan !== ""  && value.namaRole !== "" && value.keterangan.length > 5 && value.namaRole.length > 3){
                    this.disabledBtn = false;
                } else {
                    this.disabledBtn = true;
                }
            }
        }
    }
}
</script>