import Vue from 'vue';
import Router from 'vue-router';
import Cookies from "js-cookie";
import DefaultLayout from './layouts/Default.vue';

// import PersonalBlog from './views/PersonalBlog.vue';
import UserProfileLite from './views/UserProfileLite.vue';
import AddNewPost from './views/AddNewPost.vue';
import Errors from './views/Errors.vue';
import ComponentsOverview from './views/ComponentsOverview.vue';
import Tables from './views/Tables.vue';
import BlogPosts from './views/BlogPosts.vue';
import SignIn from './views/SignIn.vue';
import SignUp from './views/SignUp.vue';

//VIEW
import CreateInvitation from './views/CreateInvitation.vue';

// Master Kategori
import MasterKategori from './views/master-kategori/MasterKategori.vue';
import MasterUsers from './views/master-user/MasterUsers.vue';
import SubKategori from './views/sub-kategori/SubKategori.vue';
import MasterRole from './views/master-role/MasterRole.vue';
import MasterApproval from './views/approval-master/MasterApproval.vue';
import MasterApprovalProduk from './views/approval-produk/MasterApprovalProduk.vue';
import HomeAdmin from '@/components/HomeAdmin';


Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      redirect: 'signin',
    },
    {
      path: '/signin',
      name: 'sign-in',
      component: SignIn,
    },
    {
      path: '/signup',
      name: 'sign-up',
      component: SignUp,
    },
    {
      path: '/',
      name: 'home-app',
      component: DefaultLayout,
      redirect: '/home',
      beforeEnter: (to, from, next) => {
        const token = Cookies.getJSON("token_");
        if (token == undefined) {
          window.location.href = "/signin";
        } else {
          next();
        }
      },
      children: [
        {
          path: '/home',
          name: 'home-admin',
          component: HomeAdmin,
        },
        {
          path: '/profil',
          name: 'profil',
          component: UserProfileLite,
        },
        {
          path: '/errors',
          name: 'errors',
          component: Errors,
        },
        {
          path: '*',
          redirect: '/errors',
        },
        {
          path: '/components-overview',
          name: 'components-overview',
          component: ComponentsOverview,
        },
        {
          path: '/master-kategori',
          name: 'master-kategori',
          component: MasterKategori,
        },
        {
          path: '/master-users',
          name: 'master-users',
          component: MasterUsers,
        },
        {
          path: '/sub-kategori',
          name: 'sub-kategori',
          component: SubKategori,
        },
        // {
        //   path: '/master-role',
        //   name: 'master-role',
        //   component: MasterRole,
        // },
        {
          path: '/master-approval',
          name: 'master-approval',
          component: MasterApproval,
        },
        {
          path: 'master-approval-produk',
          name: 'master-approval-produk',
          component: MasterApprovalProduk,
        },
      ]
    },
  ],
});
