<template>
<div>
    <d-list-group flush>
        <d-list-group-item>
            <d-row>
                <d-col md="12" v-if="loading">
                    <div class="loading text-center">
                        <i class="fa fa-spinner fa-spin fa-2x"></i>
                    </div>
                </d-col>
                <d-col md="12" v-else>
                    <div class="imageEdit text-center">
                        <img :src="payload.gambarIcon" />
                    </div>
                    <d-col class="form-group">
                        <label for="feEmailAddress">Gambar Icon</label>
                        <d-input type="text" placeholder="Gambar Icon URL" v-model="payload.gambarIcon"/>
                    </d-col>
                    <d-col class="form-group">
                        <label for="feEmailAddress">Nama Kategori</label>
                        <d-input type="text" placeholder="Nama Kategori" v-model="payload.namaKategori"/>
                    </d-col>
                    <d-col class="form-group">
                        <label for="feEmailAddress">Keterangan</label>
                        <d-textarea 
                            placeholder="Keterangan" 
                            v-model="payload.keterangan"
                            :value.prop="payload.keterangan"
                        >
                        </d-textarea>
                    </d-col>
                    <d-col class="form-group text-right aliansi-button">
                        <d-button type="submit" class="btn-accent" :disabled="disabledBtn" @click="editCategory">Simpan</d-button>
                        <d-button theme="danger" @click="handleClose">Batal</d-button>
                    </d-col>
                </d-col>
            </d-row>
        </d-list-group-item>
    </d-list-group>
</div>
</template>
<script>
import { RepositoryAPI } from '@/api/repository_api';
const callApi  = RepositoryAPI.get('auth_admin');

export default {
    name: 'KategoriEdit',
    props: {
        categoryItem: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            payload: {
                kategoriId: "",
                gambarIcon: "",
                keterangan: "",
                namaKategori: ""
            },
            loading: false,
            disabledBtn: true,
            error: {}
        }
    },
    mounted() {
        this.payload.kategoriId = this.$props.categoryItem.kategoriId;
        this.payload.gambarIcon = this.$props.categoryItem.gambarIcon;
        this.payload.keterangan = this.$props.categoryItem.keterangan;
        this.payload.namaKategori = this.$props.categoryItem.namaKategori;
    },
    methods: {
       async editCategory() {
           this.loading = true;
           try {
               await callApi.editMasterCategory(this.payload);
               this.loading = true;
                this.$swal.fire(
                    'Berhasil!',
                    'Data berhasil diubah.',
                    'success'
                )
                this.loading = false;
                this.handleClose();
                this.$emit('success');
           } catch(e) {
               console.log(e);
               this.error = e.response.data;
               this.loading = false;
               this.handleClose();
               this.$emit('error', {
                   data: this.error,
                   show: true
               });
           }
        },
        handleClose() {
            this.$emit('close', false);
        }
    },
    watch: {
        payload: {
            deep: true,
            handler: function(value) {
                if (value.gambarIcon !== "" && value.keterangan !== ""  && value.namaKategori !== "" && value.gambarIcon.length > 5 && value.keterangan.length > 5 && value.namaKategori.length > 3){
                    this.disabledBtn = false;
                    this.payload.gambarIcon = value.gambarIcon;
                } else {
                    this.disabledBtn = true;
                }
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .imageEdit {
        margin-bottom: 20px;
        img {
            height: 100px;
            width: auto;
        }
    }
</style>
