<template>
<div class="message-alert" v-if="show" @click="close">
    <div class="message">
        <div class="message-status">
            {{error.data.status}} :
        </div>
        <div class="message-error">
            {{ error.data.message || error.data.error }}
        </div>
    </div>
</div>
</template>
<script>
export default {
    name: 'HandleServerError',
    props: {
        error: {
            type: Object,
            default: () => {}
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
    },
    methods: {
        close() {
            this.$emit('close', false)
        }
    },
    watch:{
        show: function(value) {
            if(value == true){ 
                setTimeout(() => {
                    this.close();
                }, 5000)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.message-alert {
    cursor: pointer;
    position: absolute;
    right: 0;
    max-width: 200px;
    top: 70px;
    z-index: 9999;
    background: #de1000;
    color: #fff;
    -webkit-border-top-left-radius: 8px;
    -webkit-border-bottom-left-radius: 8px;
    -moz-border-radius-topleft: 8px;
    -moz-border-radius-bottomleft: 8px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    -webkit-animation: fade-in-right 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: fade-in-right 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    box-shadow: -2px 3px 5px 0px rgba(163,163,163,0.75);
    -webkit-box-shadow: -2px 3px 5px 0px rgba(163,163,163,0.75);
    -moz-box-shadow: -2px 3px 5px 0px rgba(163,163,163,0.75);
    .message {
        padding: 15px;
        font-size: 12px;
        &-status {
            font-size: 10px;
        }
    }
}

@-webkit-keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
</style>