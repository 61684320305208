<template>
    <d-container fluid class="main-content-container px-4">
        <!-- Page Header -->
        <d-row no-gutters class="page-header py-4">
            <d-col col sm="4" class="text-center text-sm-left mb-4 mb-sm-0">
                <h3 class="page-title">Buat Undangan</h3>
            </d-col>
        </d-row>

        <!-- Content -->
        <d-row>
            <d-col lg="4">
                <side-menu 
                    @openPage="open"
                    :style-class="pageSelect"
                />
            </d-col>
            <d-col lg="8">
                <keep-alive>
                    <component
                        :is="pageSelect"
                        @openPage="open"
                    >
                    </component>
                </keep-alive>
            </d-col>
        </d-row>

    </d-container>
</template>

<script>
import SideMenu from '@/components/undangan/SideMenu.vue';
import BasicInfo from '@/components/undangan/BasicInfo.vue';
import PilihTemplate from '@/components/undangan/PilihTemplate.vue';

export default {
    name: 'create-invitation',
    data() {
        return {
            pageSelect: 'PilihTemplate',
        }
    },
    components: {
        SideMenu,
        BasicInfo,
        PilihTemplate,
    },
    methods: {
        open(value) {
            this.pageSelect = value;
        }
    }
};

</script>