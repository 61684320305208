<template>
<div>
    <d-list-group flush>
        <d-list-group-item>
            <d-row>
                <d-col md="12" v-if="loading">
                    <div class="loading text-center">
                        <i class="fa fa-spinner fa-spin fa-2x"></i>
                    </div>
                </d-col>
                <d-col md="12" v-else>
                    <d-col class="form-group">
                        <label for="feEmailAddress">Master Kategori <span class="load-master" v-if="loadMaster">Loading...</span></label>
                        <d-form-select v-model="payload.kategoriId" :options="masterKategori" />
                    </d-col>
                    <d-col class="form-group">
                        <label for="feEmailAddress">Nama Sub Kategori</label>
                        <d-input type="text" placeholder="Nama Sub Kategori" v-model="payload.namaSubKategori"/>
                    </d-col>
                    <d-col class="form-group">
                        <label for="feEmailAddress">Keterangan</label>
                        <d-textarea 
                            placeholder="Keterangan" 
                            v-model="payload.keterangan"
                            :value.prop="payload.keterangan"
                        >
                        </d-textarea>
                    </d-col>
                    <d-col class="form-group text-right aliansi-button">
                        <d-button type="submit" class="btn-accent" :disabled="disabledBtn" @click="editData">Simpan</d-button>
                        <d-button theme="danger" @click="handleClose">Batal</d-button>
                    </d-col>
                </d-col>
            </d-row>
        </d-list-group-item>
    </d-list-group>
</div>
</template>
<script>
import { RepositoryAPI } from '@/api/repository_api';
const callApi  = RepositoryAPI.get('auth_admin');

export default {
    props: {
        editItem: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            payload: {
                kategoriId: null,
                keterangan: "",
                namaSubKategori: "",
                subKategoriId: ""
            },
            loading: false,
            disabledBtn: true,
            payloadMaster: {
                pageNumber: 1,
                pageSize: 50,
                sortBy:'created',
                sortDirection: 'DESC'
            },
            masterKategori: [
                { value: null, text: 'Pilih master kategori' },
            ],
            loadMaster: false,
            error: {}
        }
    },
    mounted() {
        this.payload.kategoriId = this.$props.editItem.kategoriId;
        this.payload.namaSubKategori = this.$props.editItem.namaSubKategori;
        this.payload.keterangan = this.$props.editItem.keterangan;
        this.payload.subKategoriId = this.$props.editItem.subKategoriId;
        this.fetchMaster();
    },
    methods: {
        async fetchMaster() {
            this.loadMaster = true;
            try {
                const res = await callApi.getMasterKategoriAll(this.payloadMaster);
                const data = res.data.data.data;
                data.forEach((item) => {
                    const list = {
                        value: item.kategoriId,
                        text: item.namaKategori
                    };
                    this.masterKategori.push(list);
                });
                this.loadMaster = false;
            } catch(e) {
                console.log(e)
            }
        },
       async editData() {
           this.loading = true;
           try {
               await callApi.editSubKategori(this.payload);
               this.loading = true;
                this.$swal.fire(
                    'Berhasil!',
                    'Data berhasil diubah.',
                    'success'
                )
                this.loading = false;
                this.handleClose();
                this.$emit('success');
           } catch(e) {
                console.log(e.response);
                this.error = e.response.data;
                this.loading = false;
                this.handleClose();
                this.$emit('error', {
                    data: this.error,
                    show: true
                });
           }
        },
        handleClose() {
            this.$emit('close', false);
        }
    },
    watch: {
        payload: {
            deep: true,
            handler: function(value) {
                if (value.kategoriId !== null && value.keterangan !== ""  && value.namaSubKategori !== "" && value.keterangan.length > 5 && value.namaSubKategori.length > 3){
                    this.disabledBtn = false;
                } else {
                    this.disabledBtn = true;
                }
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .load-master {
        font-size: 10px;
        color: #ddd;
    }
</style>
